// Generated by Framer (e8e43ad)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/AuH99mcsl";

const cycleOrder = ["OFMI2JNQR"];

const serializationHash = "framer-bMz6B"

const variantClassNames = {OFMI2JNQR: "framer-v-slzl7h"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, text, width, ...props}) => { return {...props, avSdS9Imn: text ?? props.avSdS9Imn ?? "We reserve the rights to change prices and revise the resources usage policy in any moment."} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, avSdS9Imn, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "OFMI2JNQR", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-slzl7h", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"OFMI2JNQR"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-3tonsf"} data-framer-name={"Round"} layoutDependency={layoutDependency} layoutId={"tWPTFvyx6"} style={{backgroundColor: "rgb(240, 109, 45)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1lspgtx"} data-styles-preset={"AuH99mcsl"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-6d05c7e7-3db1-411c-8b7a-d2c7f93bf6a9, rgb(84, 77, 73)))"}}>We reserve the rights to change prices and revise the resources usage policy in any moment.</motion.p></React.Fragment>} className={"framer-sxhznj"} data-framer-name={"Paragraph"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"g1djUb_M1"} style={{"--extracted-r6o4lv": "var(--token-6d05c7e7-3db1-411c-8b7a-d2c7f93bf6a9, rgb(84, 77, 73))"}} text={avSdS9Imn} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bMz6B.framer-3hxe5q, .framer-bMz6B .framer-3hxe5q { display: block; }", ".framer-bMz6B.framer-slzl7h { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 803px; }", ".framer-bMz6B .framer-3tonsf { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 8px); position: relative; width: 8px; }", ".framer-bMz6B .framer-sxhznj { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bMz6B.framer-slzl7h { gap: 0px; } .framer-bMz6B.framer-slzl7h > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-bMz6B.framer-slzl7h > :first-child { margin-left: 0px; } .framer-bMz6B.framer-slzl7h > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30.5
 * @framerIntrinsicWidth 803
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"avSdS9Imn":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertLbqnPQb5: React.ComponentType<Props> = withCSS(Component, css, "framer-bMz6B") as typeof Component;
export default FramertLbqnPQb5;

FramertLbqnPQb5.displayName = "Bullet";

FramertLbqnPQb5.defaultProps = {height: 30.5, width: 803};

addPropertyControls(FramertLbqnPQb5, {avSdS9Imn: {defaultValue: "We reserve the rights to change prices and revise the resources usage policy in any moment.", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramertLbqnPQb5, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})